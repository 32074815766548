import { useEffect, useState, useRef } from "react";
import QrReader from 'react-qr-scanner'
import vParser from 'vdata-parser';


import logo from './logo.svg';
import './App.css';

import { formatISO, toDate } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';


// Appsync
import { API, Auth } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

// MDB
import {
  MDBContainer,
  MDBSelect,
  MDBInputGroup,
  MDBBtn,
  MDBDatepicker, 
  MDBTextArea,
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBTypography,
  MDBIcon,
  MDBSpinner,
  MDBAccordion, MDBAccordionItem, MDBRipple, MDBCol, MDBRow
} from 'mdb-react-ui-kit';

const timeZone = 'Europe/Berlin';

async function getUserEmail() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    //console.log(user)
    const userEmail = user.attributes.email;
    return userEmail;
  } catch (error) {
    console.error('Error fetching user email:', error);
    return null;
  }
}

function formatCurrentDate() {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();

  return `${day}.${month}.${year}`;
}

function App() {

const [feedbackDelay, setFeedbackDelay] = useState(3000)
  const [showScanner, setShowScanner] = useState(true)
  const [isLoadingScanner, setIsLoadingScanner] = useState(false)

  const reloadScanner = () => {
    setShowScanner(false)
    setIsLoadingScanner(true)
    setTimeout(() => {
      setShowScanner(true)
      setIsLoadingScanner(false)
    }, 500)
  }

  const handleChangeFeedbackDelay = (e) => {
    setFeedbackDelay(parseInt(e.target.value))
    reloadScanner()
  }


  // Room Select
  const [room, setRoom] = useState(0)

  const handleRoomChange = (e) => {
    setRoom(e.value)
    reloadScanner()
  }

  // Date Select
  const [date, setDate] = useState(formatCurrentDate())

  const handleDateChange = (e) => {
    setDate(e);
    reloadScanner()
  }

  // Lectures

  const [lectures, setLectures] = useState([])

  const [selectedLecture, setSelectedLecture] = useState(null)


  useEffect(() => {
    const getLectures = async () => {
      try {
        const response = await fetch('./lectures.json');
        const lecturesData = await response.json();
    
        let filteredLectures = lecturesData;
    
        if (room !== 0 && room !== "0") {
          //console.log("room", room)
          filteredLectures = filteredLectures.filter((lecture) => lecture.Room === room);
        }
    
        if (date !== null) {
          const [day, month, year] = date.split('.');
          const convertedDate = `${year}-${month}-${day}`;
          filteredLectures = filteredLectures.filter((lecture) => lecture.date === convertedDate);
        }
    
        const sortedLectures = filteredLectures.sort((a, b) => {
          return a.time.localeCompare(b.time);
        });
    
        setLectures(sortedLectures);
      } catch (error) {
        console.error('Error loading lectures:', error);
      }
    };
    
    getLectures()
  }, [room, date])
  

  // Update Participant Count - create CountPpdate

  const wrapperParticipantsCountUpdate = useRef(null)
  const participantsCountUpdateTrigger = useRef(null)

  const [participantCount, setParticipantCount] = useState("")

  const updateParticipantCount = async (lectureId) => {
    try {
      const response = await API.graphql({
        query: mutations.createCountUpdate,
        variables: {
          input: {
            count: participantCount,
            lectureCountUpdatesId: selectedLecture,
            dateTime: formatISO(utcToZonedTime(new Date(), timeZone))
          }
        }
      
      });

      setParticipantCount(0)
      reloadScanner()
    } catch (error) {
      console.log(error)
    }
  }


const commentUpdateTrigger = useRef(null)
const wrappercommentUpdate = useRef(null)

// Comment Update
const [comment, setComment] = useState("")

const updateComment = async (lectureId) => {
  try {
    
    const response = await API.graphql({
      query: mutations.createCommentUpdate,
      variables: {
        input: {
          text: comment,
          lectureCommentUpdatesId: selectedLecture,
          dateTime: formatISO(utcToZonedTime(new Date(), timeZone))
        }
      }

    });

    setComment("")
    reloadScanner()
  } catch (error) {
    console.log(error)
  }
}




// QR Scanner
const qrDelay = 100
const [qrdata, setQrdata] = useState({})



const postScanData = async (data) => {
  try {
    let variables = {
      input: {
        type: "checkin",
        name: data.firstName,
        lastName:data.lastName,
        company: data.company,
        dateTime: formatISO(utcToZonedTime(new Date(), timeZone)),
        scanningPerson: await getUserEmail()
      }
    }
    if(selectedLecture) {
      variables.input.lectureCheckinsId = selectedLecture
    }
    if(room) {
      variables.input.room = room
    }
    const response = await API.graphql({
      query: mutations.createScan,
      variables
    });
  } catch (error) {
    console.log(error)
  }
}



const handleScan = data => {
  if (data) {
    //console.log(data)
    var card = vParser.fromString(data.text)
    //console.log("QRDATA",card)
    const vcardData = {
      firstName: card.VCARD.N?.split(';')[1],
      lastName: card.VCARD.N?.split(';')[0],
      email: card.VCARD.EMAIL?.[0]?.value,
      phone: card.VCARD.TEL?.[0]?.value,
      company: card.VCARD.ORG
    }
    setQrdata(vcardData)
    setShowScanner(false)
    postScanData(vcardData)
    setTimeout(() => {
      setShowScanner(true)
    }, feedbackDelay)
  }
}
  
  const handleError = err => {
    console.error(err)
  }

  // QR Camera Select
  const [cameraId, setCameraId] = useState(null)
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)

  const getDevices = async () => {
    setLoading(true)
    await navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          const videoSelect = []
          devices.forEach((device) => {
            if (device.kind === 'videoinput') {
              videoSelect.push(device)
            }
          })
          return videoSelect
        }).then((devices) => {
          //console.log("DEVICES", devices)
          setCameraId(devices[0].deviceId)
          setDevices(devices)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
        })
  }

  useEffect(() => {
    
    getDevices()
  }, [])


  // Manual Checkin
  const [manualCheckinMinus, setManualCheckinMinus] = useState(1)
  const [manualCheckinPlus, setManualCheckinPlus] = useState(1)

  const handleManualCheckin = async (type) => {
    try {
      let variables = {
        input: {
          type: type,
          count: type === "add" ? manualCheckinPlus : manualCheckinMinus,
          dateTime: formatISO(utcToZonedTime(new Date(), timeZone)),
          scanningPerson: await getUserEmail()
        }
      }
      if(selectedLecture) {
        variables.input.lectureCheckinsId = selectedLecture
      }
      if(room) {
        variables.input.room = room
      }
      const response = await API.graphql({
        query: mutations.createCountChange,
        variables
      });
      reloadScanner()
    } catch (error) {
      console.log(error)
    }
  }
  


  
  
  return (
    <div className="App">
      <MDBContainer className="p-4 p-md-5" style={{maxWidth: "800px"}}>
      <MDBAccordion initialActive={1} className="mb-3 mb-md-4">
        <MDBAccordionItem collapseId={1} headerTitle='Einstellungen'>
          <label className="mb-4 w-100">
            Raum auswählen
            <select
              className="form-select"
              value={room}
              onChange={(e) => handleRoomChange(e.target)}
            >
              <option value={0}>Alle</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5"}>5</option>
              <option value={"6"}>6</option>
              <option value={"7"}>7</option>
              <option value={"8"}>8</option>
              <option value={"9"}>9</option>
              <option value={"Studio"}>Studio</option>
            </select>
          </label>
          <MDBDatepicker
            className="mb-4"
            label="Datum auswählen"
            format="dd.mm.yyyy"
            defaultValue={formatCurrentDate()}
            onChange={(e) => handleDateChange(e)}
          />

          
          <label className="w-100">
            Kamera auswählen
            <select value={cameraId || ""} className="form-select mb-2" aria-label="Default select example" onChange={(e) => setCameraId(e.target.value)}>
            <option value={""} disabled>Kamera auswählen</option>
            {devices.map((device, index) => (
              <option value={device.deviceId}>{device.label}</option>
            ))}
          </select>
          <label className="w-100">
            Scan Bestätigung
            <select
              className="form-select mb-2"
              value={feedbackDelay}
              onChange={(e) => handleChangeFeedbackDelay(e)}
            >
              <option value={"1000"}>1 Sekunde</option>
              <option value={"2000"}>2 Sekunden</option>
              <option value={"3000"}>3 Sekunden</option>
              <option value={"4000"}>4 Sekunden</option>
            </select>
          </label>
        </label>
        </MDBAccordionItem>
      </MDBAccordion>
      <label className="w-100">
            Vortrag auswählen
            <select
              className="form-select mb-2"
              defaultValue={null}
              onChange={(e) => setSelectedLecture(e.target.value)}
            >
              <option value={null} disabled>
                Vortrag auswählen
              </option>
              {lectures.map((lecture, index) => (
                <option key={index} value={lecture.id}>
                  {lecture.name}
                </option>
              ))}
            </select>
          </label>
    
          <div ref={wrapperParticipantsCountUpdate}>

          </div>
        <MDBInputGroup className='mb-3'>
          <input className='form-control' placeholder="Anzahl Teilnehmer" type='number' value={participantCount} onChange={(e) => setParticipantCount(e.target.value)} />
          <MDBBtn onClick={() => updateParticipantCount()} outline ref={participantsCountUpdateTrigger}>Update</MDBBtn>
        </MDBInputGroup>
        <MDBAlert
        color='success'
        className="w-100 position-relative"
        style={{marginTop: "-3.5rem", height: "3rem", paddingTop: "0.75rem", zIndex: 1000}}
        autohide
        delay={3000}
        triggerRef={participantsCountUpdateTrigger}
        containerRef={wrapperParticipantsCountUpdate}
      >Teilnehmeranzahl erfolgreich übermittelt</MDBAlert>

        <div ref={wrappercommentUpdate}>

          </div>
        <MDBTextArea value={comment} onChange={(e) => setComment(e.target.value)} className="mb-2" label='Bemerkung' id='textAreaExample' rows={2} />
        <MDBBtn onClick={() => updateComment()} outline ref={commentUpdateTrigger}>Bemerkung hinzufügen</MDBBtn>
        <MDBAlert
        color='success'
        className="w-100 position-relative"
        style={{marginTop: "-6.3rem", height: "4rem", paddingTop: "1.2rem", zIndex: 1000}}
        autohide
        delay={3000}
        triggerRef={commentUpdateTrigger}
        containerRef={wrappercommentUpdate}
      >Bemerkung erfolgreich übermittelt</MDBAlert>
        {/* <p>Anzahl kann jederzeit geupdatet werden wenn sich die Teilnehmerzahl deutlich ändert oder ein neuer Vortrag beginnt und noch viele aus dem alten Vortrag eingecheckt sind.</p> */}
        <h4 className="mt-4">Check-In</h4>
        {/* <MDBSelect
          label='Kamera auswählen'
          className="mb-4"
          onValueChange={(e) => setCameraId(e)}
          data={[
            ...devices.map((device, index) => (
                {text: device.label, value: device.deviceId}
              ))
          ]}
        /> */}
        
        {showScanner ?
          <QrReader
            onClick={() => reloadScanner()}
            data={qrdata}
            delay={qrDelay}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            constraints={cameraId && ({ audio: false, video: { deviceId: cameraId } })}
            />
         : isLoadingScanner ? <MDBSpinner grow color='primary'>
        <span className='visually-hidden'>Loading...</span>
      </MDBSpinner> :<MDBCard className="border border-success border-5">
          <MDBCardBody>
              <h4>Checkin Erfasst</h4>
              <MDBTypography tag='p' variant='h3'>
                Raum {room}
              </MDBTypography>
              <MDBTypography tag='p' variant='h6'>
                {qrdata.firstName} {qrdata.lastName}
              </MDBTypography>
              <MDBTypography tag='p' variant='h6' className='text-uppercase'>
                {qrdata.company}
              </MDBTypography>
              <MDBTypography tag='p' variant='h1' className="text-success">
                <MDBIcon fas icon="check" />
              </MDBTypography>
          </MDBCardBody>
         </MDBCard>}
        <MDBRow className="mt-2">
        <MDBCol size="12">
          <p className="text-danger">Nur verwenden, wenn Scan nicht klappt:</p>
        </MDBCol>
          <MDBCol size="6">
            <MDBInputGroup className='mb-3'>
              <MDBBtn onClick={() => handleManualCheckin("substract")} outline><MDBIcon fas icon="minus" /></MDBBtn>
              <input value={manualCheckinMinus} onChange={e =>  setManualCheckinMinus(e.target.value)} className='form-control' type='number' />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol size="6">
            <MDBInputGroup className='mb-3'>
              <input value={manualCheckinPlus} onChange={e =>  setManualCheckinPlus(e.target.value)} className='form-control' type='number' />
              <MDBBtn onClick={() => handleManualCheckin("add")} outline><MDBIcon fas icon="plus" /></MDBBtn>
            </MDBInputGroup>
          </MDBCol>
        </MDBRow>
            {/* <div>
              <p>QR Data:</p>
              <pre>{JSON.stringify(qrdata, null, 2)}</pre>
              </div> */}
      </MDBContainer>
    </div>
  );
}

export default App;
