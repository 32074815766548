/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLecture = /* GraphQL */ `
  mutation CreateLecture(
    $input: CreateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    createLecture(input: $input, condition: $condition) {
      id
      name
      Referent {
        id
        name
        lastName
        lectures {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      time
      SessionNr
      Room
      Checkins {
        items {
          id
          type
          name
          lastName
          company
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCheckinsId
        }
        nextToken
      }
      CountUpdates {
        items {
          id
          count
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountUpdatesId
        }
        nextToken
      }
      CommentUpdates {
        items {
          id
          text
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCommentUpdatesId
        }
        nextToken
      }
      CountChanges {
        items {
          id
          count
          type
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountChangesId
        }
        nextToken
      }
      createdAt
      updatedAt
      referentLecturesId
      lectureReferentId
    }
  }
`;
export const updateLecture = /* GraphQL */ `
  mutation UpdateLecture(
    $input: UpdateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    updateLecture(input: $input, condition: $condition) {
      id
      name
      Referent {
        id
        name
        lastName
        lectures {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      time
      SessionNr
      Room
      Checkins {
        items {
          id
          type
          name
          lastName
          company
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCheckinsId
        }
        nextToken
      }
      CountUpdates {
        items {
          id
          count
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountUpdatesId
        }
        nextToken
      }
      CommentUpdates {
        items {
          id
          text
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCommentUpdatesId
        }
        nextToken
      }
      CountChanges {
        items {
          id
          count
          type
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountChangesId
        }
        nextToken
      }
      createdAt
      updatedAt
      referentLecturesId
      lectureReferentId
    }
  }
`;
export const deleteLecture = /* GraphQL */ `
  mutation DeleteLecture(
    $input: DeleteLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    deleteLecture(input: $input, condition: $condition) {
      id
      name
      Referent {
        id
        name
        lastName
        lectures {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      time
      SessionNr
      Room
      Checkins {
        items {
          id
          type
          name
          lastName
          company
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCheckinsId
        }
        nextToken
      }
      CountUpdates {
        items {
          id
          count
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountUpdatesId
        }
        nextToken
      }
      CommentUpdates {
        items {
          id
          text
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCommentUpdatesId
        }
        nextToken
      }
      CountChanges {
        items {
          id
          count
          type
          room
          scanningPerson
          dateTime
          createdAt
          updatedAt
          lectureCountChangesId
        }
        nextToken
      }
      createdAt
      updatedAt
      referentLecturesId
      lectureReferentId
    }
  }
`;
export const createReferent = /* GraphQL */ `
  mutation CreateReferent(
    $input: CreateReferentInput!
    $condition: ModelReferentConditionInput
  ) {
    createReferent(input: $input, condition: $condition) {
      id
      name
      lastName
      lectures {
        items {
          id
          name
          date
          time
          SessionNr
          Room
          createdAt
          updatedAt
          referentLecturesId
          lectureReferentId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReferent = /* GraphQL */ `
  mutation UpdateReferent(
    $input: UpdateReferentInput!
    $condition: ModelReferentConditionInput
  ) {
    updateReferent(input: $input, condition: $condition) {
      id
      name
      lastName
      lectures {
        items {
          id
          name
          date
          time
          SessionNr
          Room
          createdAt
          updatedAt
          referentLecturesId
          lectureReferentId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferent = /* GraphQL */ `
  mutation DeleteReferent(
    $input: DeleteReferentInput!
    $condition: ModelReferentConditionInput
  ) {
    deleteReferent(input: $input, condition: $condition) {
      id
      name
      lastName
      lectures {
        items {
          id
          name
          date
          time
          SessionNr
          Room
          createdAt
          updatedAt
          referentLecturesId
          lectureReferentId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createScan = /* GraphQL */ `
  mutation CreateScan(
    $input: CreateScanInput!
    $condition: ModelScanConditionInput
  ) {
    createScan(input: $input, condition: $condition) {
      id
      type
      name
      lastName
      company
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCheckinsId
    }
  }
`;
export const updateScan = /* GraphQL */ `
  mutation UpdateScan(
    $input: UpdateScanInput!
    $condition: ModelScanConditionInput
  ) {
    updateScan(input: $input, condition: $condition) {
      id
      type
      name
      lastName
      company
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCheckinsId
    }
  }
`;
export const deleteScan = /* GraphQL */ `
  mutation DeleteScan(
    $input: DeleteScanInput!
    $condition: ModelScanConditionInput
  ) {
    deleteScan(input: $input, condition: $condition) {
      id
      type
      name
      lastName
      company
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCheckinsId
    }
  }
`;
export const createCountUpdate = /* GraphQL */ `
  mutation CreateCountUpdate(
    $input: CreateCountUpdateInput!
    $condition: ModelCountUpdateConditionInput
  ) {
    createCountUpdate(input: $input, condition: $condition) {
      id
      count
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountUpdatesId
    }
  }
`;
export const updateCountUpdate = /* GraphQL */ `
  mutation UpdateCountUpdate(
    $input: UpdateCountUpdateInput!
    $condition: ModelCountUpdateConditionInput
  ) {
    updateCountUpdate(input: $input, condition: $condition) {
      id
      count
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountUpdatesId
    }
  }
`;
export const deleteCountUpdate = /* GraphQL */ `
  mutation DeleteCountUpdate(
    $input: DeleteCountUpdateInput!
    $condition: ModelCountUpdateConditionInput
  ) {
    deleteCountUpdate(input: $input, condition: $condition) {
      id
      count
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountUpdatesId
    }
  }
`;
export const createCountChange = /* GraphQL */ `
  mutation CreateCountChange(
    $input: CreateCountChangeInput!
    $condition: ModelCountChangeConditionInput
  ) {
    createCountChange(input: $input, condition: $condition) {
      id
      count
      type
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountChangesId
    }
  }
`;
export const updateCountChange = /* GraphQL */ `
  mutation UpdateCountChange(
    $input: UpdateCountChangeInput!
    $condition: ModelCountChangeConditionInput
  ) {
    updateCountChange(input: $input, condition: $condition) {
      id
      count
      type
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountChangesId
    }
  }
`;
export const deleteCountChange = /* GraphQL */ `
  mutation DeleteCountChange(
    $input: DeleteCountChangeInput!
    $condition: ModelCountChangeConditionInput
  ) {
    deleteCountChange(input: $input, condition: $condition) {
      id
      count
      type
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCountChangesId
    }
  }
`;
export const createCommentUpdate = /* GraphQL */ `
  mutation CreateCommentUpdate(
    $input: CreateCommentUpdateInput!
    $condition: ModelCommentUpdateConditionInput
  ) {
    createCommentUpdate(input: $input, condition: $condition) {
      id
      text
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCommentUpdatesId
    }
  }
`;
export const updateCommentUpdate = /* GraphQL */ `
  mutation UpdateCommentUpdate(
    $input: UpdateCommentUpdateInput!
    $condition: ModelCommentUpdateConditionInput
  ) {
    updateCommentUpdate(input: $input, condition: $condition) {
      id
      text
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCommentUpdatesId
    }
  }
`;
export const deleteCommentUpdate = /* GraphQL */ `
  mutation DeleteCommentUpdate(
    $input: DeleteCommentUpdateInput!
    $condition: ModelCommentUpdateConditionInput
  ) {
    deleteCommentUpdate(input: $input, condition: $condition) {
      id
      text
      lecture {
        id
        name
        Referent {
          id
          name
          lastName
          createdAt
          updatedAt
        }
        date
        time
        SessionNr
        Room
        Checkins {
          nextToken
        }
        CountUpdates {
          nextToken
        }
        CommentUpdates {
          nextToken
        }
        CountChanges {
          nextToken
        }
        createdAt
        updatedAt
        referentLecturesId
        lectureReferentId
      }
      room
      scanningPerson
      dateTime
      createdAt
      updatedAt
      lectureCommentUpdatesId
    }
  }
`;
